
<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading ">Pharmaceutical Impurities</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Pharmaceutical Impurities</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>




<!--<nav aria-label="breadcrumb"class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">Pharmaceutical Impurities</a></li>
    </ol>
</nav>-->   
<div class="container-fluid content_image">
    <div class="container">
        <div class="row">

            <!--<h3><span>Pharmaceutical Impurities</span></h3>-->

            <div class="col-lg-12 col-md-12 col-sm-4" style="padding-top:2rem">
                <p>One of the fast growing requirement in today's Pharmaceutical Industry in developing advance drugs is that of Impurity Isolation. Our Synthetic Laboratories are well qualified in providing Drug Impurity Standards. Reax specializes in manufacture and distribution of pharmaceutical impurity standard, pharmacopeia & non-pharmacopeial reference standards, drug gluconorides, drug metabolites and Isotope labelled reference standards.</p>
                 </div>
           
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-4 col4">
                <p>Our client base is spread across the world, most of them being major Pharmaceutical Companies and Natural Products manufacturers to whom we supply API, impurity standards</p>
                <div>
                    <p><a routerLink="/Products"> <button _ngcontent-soc-c86="" type="button" class="read">Our Products</button></a></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-4 col4">
                <img src="../../../assets/img/s_chemicals.svg" class="img" style="width:28rem;"/>

            </div>
        </div><!---checkin by suraj-->
    </div>
</div>