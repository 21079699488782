import { Component, OnInit , Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

import { Directive, HostListener} from '@angular/core'

import { CartService } from '../../Services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
    providers: [MessageService],
})
export class ProductDetailsComponent implements OnInit {
    alphabetvale: any; 
    HomeUrl: any;
    enquirydata: any;
    categoryId: any;
    ProductListforFilter: any = []
    ProductsList: any = []
    public form: FormGroup;
    personName: any;
    contactNumber: any;
    email: any;
    comments: any;
    cartitems = false;
    cartData = [];
    enquiryData = [];
    pInfocartData: any
    cartItemsCount: any;
    ItemId: number;
    itemSize: string;
    selectedItemforCart: any = []
    cartItemList: any = []
    toast: any;
    P_ID: any;
    cartItemList1: any[];
    whatsAppProductURL: string;

    arr: any[];
    selectedcompanys: any;
    selectedlocation: any;
    selectedexperince: any;
    searchcompanyByCategory: any = [];
    searchcomp: any;
    JobCode: any;
    searchlist: any = [];
    searchcompanyByFilter: any[] = [];

    constructor(fb: FormBuilder, public generalService: GeneralService, public cartService: CartService, public http: HttpClient, public router: Router, ) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        this.pInfocartData = JSON.parse(localStorage.getItem('cartData'))
        this.enquirydata = JSON.parse(localStorage.getItem('enquiryData'))
        if (this.pInfocartData != null) {
            this.cartData = this.pInfocartData
        }
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItemList = res

        })

        this.form = fb.group({
            'personName': ['', Validators.compose([Validators.required])],
            'contactNumber': ['', Validators.compose([Validators.required])],
            'comments': [''],
            'email': ['', Validators.compose([Validators.required])],
        });
        this.personName = this.form.controls['personName'];
        this.contactNumber = this.form.controls['contactNumber'];
        this.comments = this.form.controls['comments'];
        this.email = this.form.controls['email'];
        if (this.enquirydata != null) {
            this.form.controls['personName'].setValue(this.enquirydata[0].PersonName);
            this.form.controls['contactNumber'].setValue(this.enquirydata[0].PhoneNumber);
            this.form.controls['email'].setValue(this.enquirydata[0].MailId);
        }
        console.log(this.pInfocartData)

        var catdata = localStorage.getItem('catid')
        if (catdata != null) {
            this.categoryId = catdata
            this.FilterList(catdata);

        }
        else {
            this.categoryId = 1;
            this.FilterList(1);
        }
        localStorage.removeItem('catid');
    }

    ngOnInit() {
        debugger
        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
            this.cartItemsCount = res.length;
            this.cartItemList = res;
        })
        /// this.FilterList(1)
        // this.categoryId=1
    }
    FilterList(id) {
        debugger
        if (id == 1) {
            this.categoryId = 1
            this.GetProductsBasedOnCategoryID(id)
        }
        else if (id == 2) {
            this.categoryId = 2
            this.GetProductsBasedOnCategoryID(id)
        }
        else if (id == 3) {
            this.categoryId = 3
            this.GetProductsBasedOnCategoryID(id)
        }
        else if (id == 4) {
            this.categoryId = 4
            this.GetProductsBasedOnCategoryID(id)
        }
        else {
            this.categoryId = 5
            this.GetProductsBasedOnCategoryID(id)
        }
    }
    GetProductsBasedOnCategoryID(Id: string) {
        const CategoryId = Id;
        const uploadFile = new FormData();
        uploadFile.append("CategoryID", CategoryId);

        const url = "api/Product/GettingProductDetailsBasedOnCategoryID";
        this.generalService.PostData(url, uploadFile).then(data => {
            debugger;
            this.searchcompanyByCategory = data; // Assign data to searchcompanyByCategory
            this.ProductListforFilter = data;
        }, err => {
            // Handle error
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
            // this.spinner.hide();
        });
    }

    ProductInfo(Catalog_No) {
        debugger
        localStorage.removeItem('Cattegoryid');
        localStorage.removeItem('CatlogID');
        localStorage.setItem("CatlogID", Catalog_No)
        localStorage.setItem("cartData", JSON.stringify(this.cartData))
        localStorage.setItem("Cattegoryid", this.categoryId);
        this.router.navigateByUrl('/products-info');
    }


    countReturn() {
      
        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemList = res.length;
            return this.cartItemList
        })
        return this.cartItemList
    }
    Addtocart2(item: any) {
        debugger
        //this.cartItemList1 = [];

        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
            for (var i = 0; i < res.length; i++) {
                this.P_ID = res[i].P_ID
            }
            //this.P_ID = res[0].P_ID;
            //this.P_ID = this.cartItemList1[0].P_ID
            return this.cartItemList1

        })
        this.Addtocart1(item);


    }





    Addtocart1(item: any) {

        debugger

        // this.cartItemList1 = this.cartItemList;

        //for (var i = 0; i < this.cartItemList1.length; i++) {
        // this.P_ID = this.cartItemList1[0].P_ID
        // }
        if (this.P_ID != item.P_ID) {
            debugger
            this.ItemId = 0;
            this.itemSize = "";
            this.selectedItemforCart.push({
                P_ID: item.P_ID, Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName ? item.ChemicalName : "",
                CatChemicalName: item.CatChemicalName,
                Cas: item.Cas,
                Pack1: item.Pack1,
                Price1: item.Price1,
                Pack2: item.Pack2,
                Price2: item.Price2,
                Pack3: item.Pack3,
                Price3: item.Price3,
                Pack4: item.Pack4,
                Price4: item.Price4,
                Pack5: item.Pack5,
                Price5: item.Price5,
                Pack6: item.Pack6,
                Price6: item.Price6,
                Formula: item.Formula,
                Structure: item.Structure,
                MolecularWeight: item.MolecularWeight,
                MeltingPoint: item.MeltingPoint,
                BoilingPoint: item.BoilingPoint,
                Synonym: item.Synonym,
                Apiname: item.Apiname,
                Storage: item.Storage,
                Density: item.Density,
                PhysicalState: item.PhysicalState,
                MoistureContent: item.MoistureContent,
            })

            this.cartService.addtoCart(this.selectedItemforCart);
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
          //  this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
            this.generalService.ShowAlert('Success', 'Added to Cart Successfully.', 'success');
        }
      //  this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in cart' });
        this.generalService.ShowAlert('Warning', 'Product already exist in cart.', 'warning');
    }

    Addtocart(item: any) {
        debugger;



        // Check if size/type is not selected
       

        // Check if item already exists in cart
        const existingItem = this.cartService.cartItemList.find(cartItem => cartItem[0].P_ID === item.P_ID);
        if (existingItem) {
            // Show warning message
            this.generalService.ShowAlert('Warning', 'This item is already in the cart.', 'warning');
            return; // Do not add the item again
        }

        // Add the item to the cart
        this.selectedItemforCart.push({
            P_ID: item.P_ID, Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName ? item.ChemicalName : "",
            CatChemicalName: item.CatChemicalName,
            Cas: item.Cas,
            Pack1: item.Pack1,
            Price1: item.Price1,
            Pack2: item.Pack2,
            Price2: item.Price2,
            Pack3: item.Pack3,
            Price3: item.Price3,
            Pack4: item.Pack4,
            Price4: item.Price4,
            Pack5: item.Pack5,
            Price5: item.Price5,
            Pack6: item.Pack6,
            Price6: item.Price6,
            Formula: item.Formula,
            Structure: item.Structure,
            MolecularWeight: item.MolecularWeight,
            MeltingPoint: item.MeltingPoint,
            BoilingPoint: item.BoilingPoint,
            Synonym: item.Synonym,
            Apiname: item.Apiname,
            Storage: item.Storage,
            Density: item.Density,
            PhysicalState: item.PhysicalState,
            MoistureContent: item.MoistureContent,
        });

        // Insert or add to cart service
        
            this.cartService.addtoCart(this.selectedItemforCart);
        

        // Show success message
        this.generalService.ShowAlert('Success', 'Added to Cart Successfully.', 'success');
        this.selectedItemforCart = []; // Clear selected items AddToCart
       // this.router.navigate(['/AddToCart/']).then(() => {
            //window.location.reload(); // Reload the page after navigation
        

    }


    filterTests() {
        debugger;
        const filtered: any[] = [];
        const uploadFile = new FormData();
        uploadFile.append("Param", this.selectedcompanys);

        const url = "api/Product/Search_Products";
        this.generalService.PostData(url, uploadFile).then((data: any[]) => {
            this.searchcompanyByCategory = data; // Assign data to searchcompanyByFilter
        }, err => {
            // Handle error
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        });
    }

    clearItem(autocomplete: any) {
        this.searchlist = [];
        debugger
        autocomplete.value = '';
        this.filterTests();
        window.location.reload();
        //this.searchlist = this.searchcompany;
    }
    filter(event) {   
           debugger
        let query = event.detail.value;

        this.searchlist = [];

        this.searchlist = this.searchcompanyByCategory.filter((KR) => {
            return (
                KR.ChemicalName.toLowerCase().indexOf(query.toLowerCase()) > -1);

        })      

    }
    search(ev) {
        debugger
        if (ev) {
            this.searchcompanyByCategory = this.searchlist.filter(a => a.ChemicalName == ev.ChemicalName);
        }
        else {
            this.searchcompanyByCategory = this.searchlist

        }
    }

    keyUp(ev) {
        debugger
        this.searchcompanyByCategory = this.searchlist.filter((item) => {
            return item.ChemicalName.toLowerCase().startsWith(ev.target.value.toLowerCase())


        });
    }    sendWhatsapp(item) {
        var prodName = ""
        var str = item.ChemicalName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += + str[i];
        }
        this.whatsAppProductURL = "https://reax.gagriglobal.com/%23/Products/" + prodName
        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }
 }
  