

<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">Capabilities</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Capabilities</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>




<!--<nav aria-label="breadcrumb" class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">Capabilities</a></li>
    </ol>
</nav>-->

<div class="container-fluid content_image">
    <!--<h3><span>Capabilities</span></h3>-->
    <div class="container">

        <div class="col-lg-12">
            <div class="row" style="padding-top:2rem;">
                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                    <!--<img src="......./../../../assets/img/Large Scale.png" />-->
                    <img src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/24/ffffff/external-scale-finance-dreamstale-lineal-dreamstale.png" />
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                    <p>
                        Small scale (mg) to kilogram, Tonnage Scale manufacturing Various theoretical models have been developed which describe the growth of small businesses.
                    </p>
                </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                        <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/24/null/external-meter-energy-source-and-power-industry-flatart-icons-outline-flatarticons.png" />
                    </div>
                    <div class="col-lg-10 ">
                        <p>
                            Large scale contract Manufacturing under GMP / Non GMP with VQ and Audit support On large scale 3rd  Party manufacturing
                            facility.
                        </p>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                            <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/24/null/external-lab-chemistry-flatart-icons-outline-flatarticons-1.png" />
                        </div>
                        <div class="col-lg-10 ">
                            <p>
                                Synthesis of customized high cost small volume products, which are commercially not available Custom synthesis means then exclusive synthesis of compounds on behalf of the customer
                            </p>
                        </div>
                        </div>
                        <!--<div class="col-lg-2">
        <img src="......./../../../assets/img/Synthesis.png" />
    </div>
    <div class="col-lg-10">
        <p>Synthesis of customized high cost small volume products, which are commercially not available Custom synthesis means then exclusive synthesis of compounds on behalf of the customer
        </p>
    </div><br /><br />-->
                        <div class="row">
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                <img src="https://img.icons8.com/external-others-anggara-putra/24/null/external-online-online-healthcare-others-anggara-putra-180.png" />
                            </div>
                            <div class="col-lg-10">
                                <p>
                                    Capable of handling wide range of reactions and reagents
                                </p>
                            </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                    <img src="https://img.icons8.com/ios-filled/24/null/acid-flask.png" />
                                </div>
                                <div class="col-lg-10">
                                    <p>
                                        Organoboron Chemistry compounds are chemical compounds of boron and carbon that are organic derivatives of BH3.
                                    </p>
                                </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                        <img src="https://img.icons8.com/windows/24/null/no-parabens.png" />
                                    </div>
                                    <div class="col-lg-10">
                                        <p>
                                            Bromination reactions is a chemical reaction involving the reaction of a compound,bromine results in bromine being added to the compound.
                                        </p>
                                    </div>
                                   </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/24/null/external-lab-health-medicine-flatart-icons-outline-flatarticons.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>
                                                Hydrogenation-Pressure Reactions are usually conducted in autoclaves at temperatures about 400degree/C, under pressure of hydrogen up to 150.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/external-line-icons-royyan-wijaya/24/null/external-chemical-medicc-line-line-icons-royyan-wijaya.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>
                                                Organometallic Reactions compounds reflect the nucleophilic character of the carbon atom bonded to the metal.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/external-bearicons-detailed-outline-bearicons/24/null/external-Palladium-periodic-table-bearicons-detailed-outline-bearicons.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>
                                                Palladium, chemical element, the least dense and lowest-melting of the platinum metals of Groups 8 to 10 (VIIIb),Periods
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/wired/24/null/chemical-storage-tank.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>
                                                Ammonolysis is the process of forming amines using, as amminating agents, ammonia or primary and secondary amines.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/external-outline-black-m-oki-orlando/24/null/external-molecule-chemistry-outline-black-m-oki-orlando.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>
                                                Grignard & Organolithium Reagents are powerful bases,Because of this they cannot be used as nucleophiles on compounds
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/external-tal-revivo-bold-tal-revivo/24/null/external-drip-dry-in-shade-clothing-for-sensitive-to-sunlight-laundry-bold-tal-revivo.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>
                                                Handling air sensitive reagents like (n-BuLi, t-BuLi, LDA, LiHMDS, DIBAL-H, NaH, LAH, Diethyl Zn)
                                                An air handling unit
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/metro/24/null/temperature-sensitive.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p> High temperature and low temperature reactions (-80deg/C to 250deg/C)</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon">
                                            <img src="https://img.icons8.com/external-line-icons-royyan-wijaya/24/null/external-chemical-medicc-line-line-icons-royyan-wijaya.png" />
                                        </div>
                                        <div class="col-lg-10">
                                            <p>Expertise in asymmetric synthesis: Synthesis and Chiral Resolution</p>
                                        </div>
                                    </div>
                                </div>
        </div>
    </div>











