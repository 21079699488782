
<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">Sourcing and Procurement Services</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Sourcing and Procurement In Services</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!--<nav aria-label="breadcrumb"class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">Sourcing and Procurement Services</a></li>
    </ol>
</nav>-->
<div class="container-fluid content_image">
    <div class="container">
        <!--<h3><span>Sourcing and Procurement Services</span></h3>-->

        <div class="row">

            <div class="col-lg-12 col-md-12 col-sm-4 pr" style="padding-top:2rem;">
                <p>
                    We offer end to end sourcing solutions for the effective procurement of world-class
                    products from a low-cost market like India to our international partners.
                </p>
                <!--<div class="pointers">

                    <b>SOURCING AND PROCUREMENT DELIVERABLES :</b><br />
                    ➣ Vendor Development & Evaluation<br />
                    ➣ Identifying Toll Manufacturing Opportunities<br />
                    ➣ Pricing <br />
                    ➣ Availability<br />
                    ➣ Specifications<br />
                    ➣ Manufacturer information - name, supply capabilities, etc<br />
                    ➣ Sampling & technical Support<br />
                    ➣ Custom clearance<br />
                    ➣ Duty payment<br />
                    ➣ Delivery<br />
                    ➣ Logistics support<br />
                </div>-->
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="div_heading">Our process development in services </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Vendor Development & Evaluation
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Identifying Toll Manufacturing Opportunities
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Pricing
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Availability
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Specifications
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Manufacturer information - name, supply capabilities, etc
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Sampling & technical Support
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Custom clearance
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Duty payment
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Delivery
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Logistics support
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img src="../../../assets/img/home_bg.jpg" class="img" />
            </div>
        </div>
        <div class="pr">
        <p style="padding-bottom:3rem;padding-top:2rem;">
            Challenges are manifold, but our long lasting experience is useful in overcoming these hurdles and efficiently performing our job. Our Chemical Procurement Service can help you to optimize your purchasing process, specifically for hard to source molecules or custom chemicals, by consolidating your search through a single vendor you know and trust.
        </p>
            </div>
    </div>
</div>




