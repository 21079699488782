<div class="container-fluid margin_0">

    <!--<video controls loop oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="../../../assets/img/videohome.mp4" type="video/mp4" />
    </video>-->

    <img src="../../../../assets/Reax.gif" class="gif"/>
</div>
<div class="container">
    <div id="about" class="">
       
        <div class="row wed">
            <div class="col-lg-7 col-md-7 col-sm-8 col-xs-8">
                <div class="about-content">
                    <h2 class="heading">Why <span>&nbsp;Reax?</span></h2>
                    <p class="site_text">
                        <strong>   Reax </strong>is a Hyderabad, India based company with a primary focus on offering chemistry services to pharmaceutical companies, biotech entities, academics, biomaterials, and catalysts industry. Reaxchemicals offers custom synthesis, design sourcing and synthesis of new chemical entities, API intermediates, reference standards and specific Impurities from a milligram to kilogram level. In addition, Reaxchemicals provides process research and development for large scale manufacturing with IP & confidentiality.
                    </p>
                </div>
                <br /><br />
                <div class="about-content">

                    <h2 class="heading"><span>What We Do</span></h2>
                    <p class="site_text">
                        Custom synthesis of NCE's, Niche APIs and their advanced intermediates,  from a milligram to multi kilo &tonnage  scale.

                        Contract Manufacturing in FFS & FTE Mode.

                        Process Development for API Intermediates

                        Custom  Synthesis of building blocks & Catalogue products

                        Custom synthesis of scaffolds and new molecules

                        Supply of Commercial Raw materials on commercial scale

                        Large scale contract Manufacturing under GMP / Non GMP

                    </p>
                    <a routerLink="/about-us"><button type="button" class="read">Read More</button></a>

                </div>
            </div><!--check in by suraj-->
            <div class="col-lg-5 col-md-5 col-sm-8 col-xs-8">
                <div class="">
                    <img src="../../../../assets/img/home_bg.jpg" class="why1"/>
                </div>
            </div>
        </div>
    </div>
</div>

