
<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">Contact us</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Contact us</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
    <!--<div class="d-table">-->
<div class="container bnr">
     <!--<img src="../../../assets/ContactusBanner.jpg" style="width:100%;height:60%" />-->
            <!--<img src="../../../assets/img/reaxcontactbanner.jpeg" />-->

        </div>
    <!--</div>--

<!--<div #screen>
    <h1>Hey!</h1>
    <p>some content</p>
</div>

<button (click)="capture()">Take</button>-->

<div id="contact" class="contact-area container-fluid content_image">
    <div class="container">
        <!--<div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>&nbsp;Contact</span>&nbsp;Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>-->
        <div class="row" style="padding-top:4rem;">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">


                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div style="    font-size: 21px;
    font-weight: bold;
    padding-bottom: 2rem;">Contact Now</div>
                    <div class="d-table">

                        <div>
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Reach Us:</span>Plot no:8, Road No-15 CFC Area, IDA-Nacharam, Hyderabad, Telangana, India-500076</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:sales@reaxchemicals.com"><span>Email:</span>sales@reaxchemicals.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:+91 9347443900"><span>Call:</span>+91 9347443900</a></li>
                                <!--<li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.635020777671!2d7
8.56677511418813!3d17.429294606184218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9893b0fee
b85%3A0x6564ccb307cc005e!2sREAXCHEMICALS!5e0!3m2!1sen!2sin!4v1664350878580!5m2!1sen!2sin"
                        class="map" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade;"></iframe>
            </div>
        </div>



    </div>
</div>
