import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './pagelayout/home/home.component';
import { AboutUsComponent } from './pagelayout/about-us/about-us.component';
import { PageHeaderComponent } from './pagelayout/page-header/page-header.component';
import { PageFooterComponent } from './pagelayout/page-footer/page-footer.component';

import { ContactUsComponent } from './pagelayout/contact-us/contact-us.component';
import { ApiIntermediateKSMSComponent } from './pagelayout/api-intermediate-ksms/api-intermediate-ksms.component';
import { BuildingBlocksComponent } from './pagelayout/building-blocks/building-blocks.component';
import { AliphaticCompoundsComponent } from './pagelayout/aliphatic-compounds/aliphatic-compounds.component';
import { FineChemicalsComponent } from './pagelayout/fine-chemicals/fine-chemicals.component';
import { PyridineDerivativesComponent } from './pagelayout/pyridine-derivatives/pyridine-derivatives.component';
import { CareersComponent } from './pagelayout/careers/careers.component';
import { ClientsComponent } from './pagelayout/clients/clients.component';
import { FacilitiesComponent } from './pagelayout/facilities/facilities.component';
import { ProductDetailsComponent } from './pagelayout/product-details/product-details.component'
import { MedicinalorSyntheticChemistryServicesComponent } from './pagelayout/medicinalor-synthetic-chemistry-services/medicinalor-synthetic-chemistry-services.component';
import { ProcessRComponent } from './pagelayout/process-r/process-r.component';
import { APIIntermediatesComponent } from './pagelayout/apiintermediates/apiintermediates.component';
import { PharmaceuticalImpuritiesComponent } from './pagelayout/pharmaceutical-impurities/pharmaceutical-impurities.component';
import { CustomsynthesisofSpecialityChemicalsComponent } from './pagelayout/customsynthesisof-speciality-chemicals/customsynthesisof-speciality-chemicals.component';
import { SourcingandProcurementServicessComponent } from './pagelayout/sourcingand-procurement-servicess/sourcingand-procurement-servicess.component';
import { CapabilitiesComponent } from './pagelayout/capabilities/capabilities.component';
import { CoreservicesComponent } from './pagelayout/coreservices/coreservices.component';
const routes: Routes = [
   
   
    { path: '', component: HomeTwoComponent },
    { path: 'login', component: LoginComponent },
    { path: 'Home', component: HomeComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'PageHeader', component: PageHeaderComponent },
    { path: 'PageFooter', component: PageFooterComponent },
    { path: 'PyridineDerivatives', component: PyridineDerivativesComponent },
    { path: 'ApiIntermediateKSMS', component: ApiIntermediateKSMSComponent },
    { path: 'products-info', component: BuildingBlocksComponent },
    { path: 'AliphaticCompounds', component: AliphaticCompoundsComponent },
    { path: 'FineChemicals', component: FineChemicalsComponent },
    { path: 'Products', component: ProductDetailsComponent },
    { path: 'Infrastructur', component: ClientsComponent },
    { path: 'Specialization', component: FacilitiesComponent },
    { path: 'ProductEnquiry', component: CareersComponent },
    { path: 'MedicinalorSyntheticChemistryServices', component: MedicinalorSyntheticChemistryServicesComponent },
    { path: 'ProcessR&D', component: ProcessRComponent },
    { path: 'CustomsynthesisofSpecialityChemicals', component: CustomsynthesisofSpecialityChemicalsComponent },
    { path: 'SourcingandProcurementServicess', component: SourcingandProcurementServicessComponent },
    { path: 'APIIntermediates', component: APIIntermediatesComponent },
    { path: 'PharmaceuticalImpurities', component: PharmaceuticalImpuritiesComponent },

    { path: 'Capabilities', component: CapabilitiesComponent },
    { path: 'Coreservices', component: CoreservicesComponent },
    { path: 'ContactUs', component: ContactUsComponent }, 
    {path: 'home-One', component: HomeOneComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'home-six', component: HomeSixComponent},
    {path: 'home-seven', component: HomeSevenComponent},
    {path: 'home-eight', component: HomeEightComponent},
    {path: 'home-nine', component: HomeNineComponent},
    {path: 'home-ten', component: HomeTenComponent},
    {path: 'home-eleven', component: HomeElevenComponent},
    {path: 'home-twelve', component: HomeTwelveComponent},
    {path: 'home-thirteen', component: HomeThirteenComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    // Here add new pages component

    { path: '**', component: HomeTwoComponent } // This line will remain down from the whole pages component list
];

//@NgModule({
//    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//    exports: [RouterModule],
 
//})
//export class AppRoutingModule { }

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            //relativeLinkResolution: 'legacy',
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
}) 
export class AppRoutingModule { }
