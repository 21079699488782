<div class="topnav navb_0 " style="background-color:#0baddf;cursor:pointer;">

    <a href="tel:+91 9347443900" target="_blank" style="margin-left:9.5%;"><i class="fa fa-fw fa-phone"></i> +91 9347443900 </a>
    <a class="callto_act ma_0" href="mailto:sales@reaxchemicals.com " target="_blank" style="">
        <i class="fa fa-envelope"
           aria-hidden="true"></i>&nbsp;&nbsp;sales@reaxchemicals.com
    </a>
    &nbsp;&nbsp;
    <a href="https://api.whatsapp.com/send?phone=+91 9347443900&text=&source=&data=" target="_blank">
        <i class="fa-brands fa-whatsapp"></i>
    </a>
</div><br />
<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied"
     ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>


    <div class="container">
        <div class="brand">
            <a class="navbar-brand" routerLink="/">
                <!--<img src="../../../../assets/logo.JPG" class="logooo  "/>-->
                <img src="..\..\assets\img\Reaxlogo-web1-wobg.png" class="logooo  " />
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"style="margin-left:6rem;"></span>
                    <span class="middle-bar"style="margin-left:6rem;"></span>
                    <span class="bottom-bar"style="margin-left:6rem;"></span>
                </span>
            </button>
        </div>

        <!--checkin by suraj-->

        <div class="collapse navbar-collapse navbar_font" id="navbarSupportedContent">




            <ul class="navbar-nav ml-auto headd">
                <li class="nav-item"><span class="nav-link"><a routerLink="/home">Home</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/about-us">About&nbsp;Us</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Products">Products</a></span></li>
                <li class="nav-item dropdown">
                    <span class="nav-link dropbtn" style="font-size:14px;">
                        <a class="nav-item">
                            Services
                        </a>
                    </span>
                    <br />
                    <div class="dropdown-content" style="width: 350%;">
                        <a routerLink="/MedicinalorSyntheticChemistryServices">Medicinal & Synthetic Chemistry Services</a>
                        <a routerLink="/ProcessR&D">Process R&D</a>
                        <a routerLink="/APIIntermediates">API Intermediates</a>
                        <a routerLink="/PharmaceuticalImpurities">Pharmaceutical Impurities</a>
                        <!--<a routerLink="/CustomsynthesisofSpecialityChemicals">Custom synthesis of Speciality Chemicals</a>-->
                        <a routerLink="/SourcingandProcurementServicess">Sourcing and Procurement Services</a>
                    </div>
                </li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Coreservices">Core&nbsp;Services</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Capabilities">Capabilities</a></span></li>
                <!--<li class="nav-item"><span class="nav-link"><a routerLink="/Specialization">Specialization</a></span></li>-->
                <li class="nav-item"><span class="nav-link"><a routerLink="/Infrastructur">Infrastructure</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs">Contact&nbsp;Us</a></span></li>

                <li>

                    <a routerLink="/Products">
                
                <i class="fa-sharp fa-solid fa-magnifying-glass" style="font-size: 21px;margin-left: 20px;cursor:pointer"></i>
                </a>

                </li>

            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link"><a routerLink="/home">Home</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/about-us">About&nbsp;Us</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Products">Products</a></span>
                
                </li>
                <li class="nav-item dropdown">
                    <span class="nav-link dropbtn" style="font-size:14px;">
                        <a class="nav-item">
                            Services
                        </a>
                    </span>
                    <br />
                    <div class="dropdown-content" style="width: 350%;">
                        <a routerLink="/MedicinalorSyntheticChemistryServices">Medicinal or Synthetic Chemistry Services</a>
                        <a routerLink="/ProcessR&D">Process R&D</a>
                        <a routerLink="/APIIntermediates">API Intermediates</a>
                        <a routerLink="/PharmaceuticalImpurities">Pharmaceutical Impurities</a>
                        <!--<a routerLink="/CustomsynthesisofSpecialityChemicals">Custom synthesis of Speciality Chemicals</a>-->
                        <a routerLink="/SourcingandProcurementServicess">Sourcing and Procurement Services</a>
                    </div>
                </li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Coreservices">CoreServices</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Capabilities">Capabilities</a></span></li>
                <!--<li class="nav-item"><span class="nav-link"><a routerLink="/Specialization">Specialization</a></span></li>-->
                <li class="nav-item"><span class="nav-link"><a routerLink="/Infrastructur">Infrastructure</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs">Contact&nbsp;Us</a></span></li>
                <li>
                </li>


            </ul>
        </div>
    </div>

</nav>
<br /><br />
<br /><br />
