
<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading ">API Intermediates</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>API Intermediates</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!--<nav aria-label="breadcrumb"class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">API Intermediates</a></li>
    </ol>
</nav>-->
<div class="container-fluid content_image">
    <!--<h3>
        <span>API Intermediates </span>
    </h3>-->
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-4" style="padding-top:2rem;">
                <p>
                    <strong>  REAX </strong>is a key API Intermediates manufacturer & supplier in the global
                    marketplace. We work closely with API manufacturers and custom formulators to
                    ensure that the active pharmaceutical ingredients you require are readily
                    available. We are providing a broad range of quality pharmaceutical materials
                    at very competitive costs.
                </p>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <p>
                    Working with sophisticated technologies and API manufacturers, we ensure that the intermediates you need are readily available. Our range meets high standard of quality, reliability and effectiveness. Our active pharmaceutical ingredients are used widely by clients worldwide to manufacture a variety of products like pharmaceuticals, food, cosmetics, and more. Contributing to the daily life, the intermediates are pure, result oriented and cost effective in nature.
                </p>
                <div>
                    <a routerLink="/Products"><button _ngcontent-soc-c86="" type="button" class="read">Our Products</button></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img src="../../../assets/img/api_i.jpg" class="img" />
            </div>
        </div>
    </div>
</div>
