<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading ">Custom synthesis of Specialty Chemicals</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Custom synthesis of Specialty Chemicals</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!--<nav aria-label="breadcrumb" class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">Custom synthesis of Specialty Chemicals</a></li>
    </ol>
</nav>-->
<div class="container-fluid content_image">
    <div class="container">


        <div class="row" style="padding-top:2rem;">
            <!--<h3 class="h3">Custom synthesis of Specialty Chemicals </h3>-->

            <div class="col-lg-12 col-md-12 col-sm-4">
                <div style="color:black;">
                    <br />
                    <p>
                        We operate with the highest integrity to protect your formulations and intellectual property, and our experienced team ensures your product is delivered accurately, on time, and on budget. REX’s strict quality control systems enable us to offer seamless solutions to proficiently move your product formulation from development all the way through market realization.
                    </p>
                    <p> <strong> REAX </strong>offers custom synthesis of chemicals that are not commercially available. For your custom synthesis request, please email the following information to sales@reaxchemicals.com</p>
                </div><br />

                <!--<div class="points">
            ➣ Chemical Name, Structure, and CAS Number<br />
            ➣ Literature references (if available)<br />
            ➣ Quantity required<br />
            ➣ Purity requirements and analytical methods<br />
            ➣ Safety information (MSDS, if available)<br />
            ➣ Expected delivery date<br />
        </div>-->





            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p>
                                Chemical Name, Structure, and CAS Number
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p>
                                Literature references (if available)
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p>
                                Quantity required
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p>
                                Purity requirements and analytical methods
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p>
                                Safety information (MSDS, if available)
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p>
                                Expected delivery date
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img src="../../../assets/img/image__2.gif" class="img" />
                </div>
              
                <p style="padding-top:2rem;">
                    We offer high-quality and cost-effective services under strict confidentiality including the following:
                </p>
                <p style="padding-bottom:2rem;">
                    <strong>  Reax</strong> chemicals is the premier custom chemical blending and manufacturing company for any size business. We have the capability to formulate and produce product at every stage of the process.  Our full-service approach includes sourcing raw materials, laboratory development, pilot runs, and commercialization of any chemical blending project. Whether it’s blending a simple mix of ingredients or performing custom chemical synthesis, Reax is equipped to work side-by-side with your company to bring your project to fulfilment.
                </p>
               
            </div>
           

            <!--<div class="col-lg-5 col-md-4 col-sm-4">
        <img src="../../../assets/img/custom.jpeg" class="img" />

    </div>-->
            <!--<div class="col-lg-1"></div>-->

        </div>
    </div>
    

</div>

    
