<footer class="footer-area">
    <div class="container-fluid">
     
        <div class="row">

            <div class="col-lg-3">
                <div class="row">
                    <div class="col-lg-12">
                        <a routerLink="/">

                            <img src="..\..\assets\ReaxWhiteLogo.png" class="white" />
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">


                        <ul>

                            <li><a href="#"><i class="fab fa-facebook-f icon_"></i> </a></li>
                            <li><a href="#"><i class="fa-brands fa-twitter icon_"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-instagram icon_"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-linkedin-in icon_"></i></a></li>
                        </ul>
                            <p style="margin-left:-1rem;margin-top:2rem;"> <i class="fa-regular fa-copyright"></i> REAX All rights reserved</p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-3 use ">
                <h3>Useful Links</h3>
                <p><a routerLink="/" (click)="onClick('home')">Home</a></p>
                <p><a routerLink="/about-us">About US</a></p>
                <p><a routerLink="/Products">Products</a></p>
                <p><a (click)="onClick1()" routerlink="/Coreservices">Core&nbsp;Services</a></p>

                <p><a routerLink="/ContactUs">Contact&nbsp;US</a></p>
            </div>


            <div class="col-lg-3 col3 cont">
                <a routerLink="/ContactUs">
                    <h3>Contact us</h3>
                </a>

                <a href="tel:+91 9347443900" target="_blank" style="margin-left:0rem;"><i class="fa fa-fw fa-phone"></i> +91 9347443900 </a><br /><br />
                <a class="callto_act ma_0" href="mailto:sales@reaxchemicals.com " target="_blank" style="margin-left:0rem;">
                    <i class="fa fa-envelope"
                       aria-hidden="true"></i>&nbsp;&nbsp;sales@reaxchemicals.com
                </a><br />
                &nbsp;&nbsp;
                <!--<a href="https://api.whatsapp.com/send?phone=+91 9347443900&text=&source=&data=" target="_blank">
            <i class="fa-brands fa-whatsapp"></i>
        </a>-->

            </div><br />

                  <div class="col-lg-3  add">

                      <h3>Address</h3>
                      <p>Plot no:8,No-15 CFC ,</p>
                      <p>IDA-Nacharam, Hyderabad</p>
                      <p>Telangana, India 500076.</p>
                      <br /><br />
                      
                  
            </div>
            
        </div>
        </div>
       
        
    
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>


