<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">Process R&D</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Process R&D</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>


<!--<nav aria-label="breadcrumb"class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/Home"></a>Home</li>
        <li class="breadcrumb-item"><a style="color:white;">Process R&D</a></li>
    </ol>
</nav>-->
<!--<nav aria-label="breadcrumb" class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;"> Process R&D</a></li>
    </ol>
</nav>-->
<div class="container-fluid content_image">

    <!--<h3>
        <span>
            Process R&D 
        </span>
    </h3>-->
    <div class="container">
        <div class="row" style="margin-top:2rem;">

            <div class="col-lg-12 col-md-12 col-sm-4 pr">
                <p>
                    Our process development services are focused on the need to develop scalable and robust synthetic routes under a tight timeline. We scale up our clients' chemistry and/or suggest, develop and optimize alternate synthetic routes.
                </p>
                <p>
                    Our process development services are focused on the need to develop scalable and robust synthetic routes under a tight timeline. We scale up our clients' chemistry and/or suggest, develop and optimize alternate synthetic routes. REAX will provide you with the appropriate solution to meet your expectations on quality, quantity and regulatory needs within the relevant timeline requirements.
                </p>
                <!--<div class="pointers">
                    <b>Our process development services include:</b><br />
                    ➣ Chemical route selection <br />
                    ➣ Process optimization and scale-up<br />
                    ➣ HSE assessment<br />
                    ➣ Process validation<br />
                    ➣ Development of analytical methods and validation<br />
                    ➣ Regulatory filings<br />
                </div>-->

            </div>
           
        </div>

        <div class="row">
            <div class="div_heading">Our process development services include</div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:1.2rem;">

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Chemical route selection

                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Process optimization and scale-up
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            HSE assessment
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Process validation
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Development of analytical methods and validation
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Regulatory filings
                        </p>
                    </div>
                </div>
                <div class="div_style">
                    <p style="margin-top:0px;padding-left:0px !important;" class="p_bg_l">
                        Our services include all areas of Advanced Intermediates and API product development and cGMP compliant
                        manufacturing-from few grams to multi-ton scale.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-4">
                <img src="../../../assets/img/lab__1.svg" class="img" style="width:100%;" />
            </div>
        </div>
    </div>
</div>
