<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading ">Medicinal & Synthetic Chemistry Services</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Medicinal & Synthetic Chemistry Services</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>


<!--<nav aria-label="breadcrumb"  class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">Medicinal or Synthetic Chemistry Services</a></li>

    </ol>
</nav>-->
<div class="container-fluid content_image">
    <div class="container">

        <!--<h3><span>Medicinal 1 or Synthetic Chemistry Services</span></h3>-->

        <div class="row">

            <div class="col-lg-7 col-md-7 col-sm-4" style="margin-top: 2rem;">
                <p>
                    <strong> REAX </strong>has a dedicated team focused on medicinal chemistry services and have
                    consistently created value for our customers through the supply of novel
                    intermediates and fragments plus supporting in house projects through a flexible
                    range of collaborative R&D Services.
                </p>
                <br />
                <li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs"><button _ngcontent-kre-c86="" type="button" class="read">Contact REAX</button></a></span></li>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-4 col4">
                <img src="../../../assets/img/images__1.jpg" />

            </div>
        </div>
    </div><!--check in by suraj-->
</div>
<!--<li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs">Contact&nbsp;Us</a></span></li>-->