import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicinalor-synthetic-chemistry-services',
  templateUrl: './medicinalor-synthetic-chemistry-services.component.html',
  styleUrls: ['./medicinalor-synthetic-chemistry-services.component.scss']
})
export class MedicinalorSyntheticChemistryServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
