<div class="container">
    <div class="row">
        <div class="col-11">
            <div class="fine">
                <h2>Fine Chemicals :</h2>
                <p>
                    Reaxchemicals offering and sourcing of organic& inorganic fine chemicals backed by technological expertise, high-quality and high-purity manufacturing, and innovative application support.Our business is modelled around the changing customer demands and our ability to offer value-added innovative solutions to meet and even exceed these demands.
                    We would welcome receiving your specific inquiries for products listed below, along with quantity desired, specifications and required delivery schedule, to enable us to quote the most competitive prices and favorable terms
                </p>
            </div>
        </div>
    </div>
</div>
