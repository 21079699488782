
<div id="welcome" class="welcome-area pt-100 pb-70">

    <div class="container">
        <div class="section-title">
          
            <h2>Our Services</h2>
            <!--<h2>Welcome to <span>Nutrigene</span></h2>-->
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            --->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="MedicinalorSynthetic" style="border-radius:18px 18px 18px 18px  !important;">
                    <div class="single-box">
                        <div style="width: 7rem;">
                            <img src="../../../../assets/img/building.svg" />
                        </div>
                        <h3>Medicinal & Synthetic Chemistry Services</h3>
                        <p class="card_text">
                            REAX has a dedicated team focused on medicinal chemistry
                            services and have consistently created value for our customers through the supply of novel
                            intermediates and fragments plus supporting in-house projects.
                        </p>
                        <a routerLink="/MedicinalorSyntheticChemistryServices" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="Process" style="border-radius:18px 18px 18px 18px  !important;">
                    <div class="single-box">
                        <div style="width:7rem;">
                            <img src="../../../../assets/img/r&d.svg" />
                        </div>
                        <h3>Process R&D</h3>
                        <br />
                        <p class="card_text">
                            Our process development services are focused on the need to develop scalable and robust synthetic
                            routes under a tight timeline.We scale up our clients'
                            chemistry and/or suggest,develop and optimize alternate synthetic routes.
                        </p>
                        <a routerLink="/ProcessR&D" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="api" style="border-radius:18px 18px 18px 18px  !important;">
                    <div class="single-box">
                        <div style="width:7rem;">
                            <img src="../../../../assets/img/api.svg" />

                        </div>
                        <h3>API Intermediates</h3>
                        <p class="card_text">
                            REAX is a key API Intermediates manufacturer & supplier in the global marketplace.
                            We work closely with API manufacturers and custom formulators to ensure that the active
                            pharmaceutical ingredients you require are readily available.
                        </p>
                        <a routerLink="/APIIntermediates" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <!--</div>
            <div class="row justify-content-center">-->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="PharmaceuticalImpurities" style="border-radius:18px 18px 18px 18px  !important;">
                    <div class="single-box">
                        <div style="width:7rem;">
                            <img src="../../../../assets/img/Impurities.svg" />
                        </div>
                        <h3>Pharmaceutical Impurities</h3>
                        <br />
                        <p class="card_text">
                            One of fast growing requirement in today's Pharmaceutical Industry in
                            developing advance drugs is that of Impurity Isolation.Our Synthetic
                            Laboratories are well qualified in providing Drug Impurity Standards.<br /><br />
                        </p>
                        <a routerLink="/PharmaceuticalImpurities" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <!--<div class="col-lg-4 col-md-6 col-sm-6">
                <div class="customsynthesis" style="border-radius:18px 18px 18px 18px  !important;">
                    <div class="single-box">
                        <div style="width:7rem;">
                            <img src="../../../../assets/img/s_chemicals.svg" />
                        </div>
                        <h3>Custom synthesis of Speciality Chemicals</h3><br />
                        <p class="card_text">
                            Reax chemicals is the premier custom chemical blending and
                            manufacturing company for any size business.We have capability to
                            formulate and produce product at every stage process .<br /><br />
                        </p>
                        <a routerLink="/CustomsynthesisofSpecialityChemicals" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>-->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="Sourcing" style="border-radius:18px 18px 18px 18px  !important;">
                    <div class="single-box">
                        <div style="width:7rem;">
                            <img src="../../../../assets/img/cart.svg" />
                        </div>
                        <h3>Sourcing and Procurement Services</h3>
                        <p class="card_text">
                            We offer end to end sourcing solutions for the effective procurement of world-class products from a
                            low-cost market like India to our international partners,consolidating your search through a single vendor you know and trust.
                        </p>
                        <a routerLink="/SourcingandProcurementServicess" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>

