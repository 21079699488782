<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">About Us</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>About Us</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid content_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="margin-top:2rem;">
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 abt">
                        <p>
                            REAX focus on offering chemistry services to pharmaceutical, biotech, top academia,
                            speciality chemical industries all across the globe. <b>
                                The company was founded in  2019, Research facility situated at IDA-Nacharam Industry Area, Hyderabad, Telangana.
                            </b>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            We provide Fast, Reliable, Cost effective delivery & multifaceted synthesis includes Custom Synthesis, Fine chemicals,  Process Development of Intermediates & Pharma Impurities from mg to kg scale and <b>delivered within the stipulated  timelines as per the client requirement.</b>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            We have well experienced Scientific & Management Team over the experience of 12 years in
                            the pharmaceutical field.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Our principal role is to identify and understand the complete requirement of the
                            client to support them better.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            We satisfy the client expectations by<b> supplying the high purity & quality products.</b>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            Transparent communication regarding projects to our Clients at regular intervals.
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <img src="../../../assets/chemical.gif" />
            </div>
        </div>
    </div>
</div>
















<div class="container">

    <!--<div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-7 col8">
            <b>  ➣</b>   <p>
                REAX focus on offering chemistry services to pharmaceutical, biotech, top academia,
                speciality chemical industries all across the globe. <b>
                    The company was founded in  2019, Research facility situated at IDA-Nacharam Industry Area, Hyderabad, Telangana
                    .
                </b>
            </p><br />
            <b>  ➣</b>  <p>

                We provide Fast, Reliable, Cost effective delivery & multifaceted synthesis includes Custom Synthesis, Fine chemicals,  Process Development of Intermediates & Pharma Impurities from mg to kg scale and <b>delivered within the stipulated  timelines as per the client requirement.</b>
            </p><br />
            <b>  ➣</b>
            <p>
                We have well experienced Scientific & Management Team over the experience of 12 years in the pharmaceutical field.
            </p><br />
            <b>  ➣</b>
            <p>
                Our principal role is to identify and understand the complete requirement of the client to support them better.
            </p><br />
            <b>  ➣</b>
            <p>
                We satisfy the client expectations by<b> supplying the high purity & quality products.</b>
            </p><br />
            <b>  ➣</b>  <p>
                Transparent communication regarding projects to our Clients at regular intervals.
            </p>

            <p>
                We partner with companies across the world to establish and maintain strong relationships that allow us to source the highest quality chemical products available. At the forefront of technological innovation within the chemical industry is where we flourish.Reax chemicals applies cutting-edge sourcing strategies to your supply chain as an extension of your business. Whether your raw material needs are for Pharmaceutical Industry, Reaxchemicals will be able to provide you with the best possible solution.
            </p>
        </div>
        <div class="col-lg-4">
            <img src="../../../assets/img/aboutus.jpg" />
        </div>
    </div>-->
    <br /><br />
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="Sourcing" style="border-radius:18px 18px 18px 18px  !important;">
                <div class="single-box">
                    <div class="icon1">
                        <img src="../../../assets/vision.svg" style="width: 20rem;" />
                    </div>
                    <div class="v_m_heading">Vision</div>
                    <p>
                        Best Quality Products,
                        Most Reliable services,
                        Customer focus,Quick adaptability,Integrity,IP &Confidentiality,
                        Safety, Core values are the organization's long-term beliefs and principles that guide employee behavior.
                        <br />
                    </p>
                </div>
            </div>
        </div>

        <!--<div class="col-lg-4 col-md-6 col-sm-6">
            <div class="Sourcing" style="border-radius:18px 18px 18px 18px  !important;">
                <div class="single-box">
                    <div class="icon">
                        <img src="../../../assets/img/mission.png" />
                    </div>
                    <h3>Mission</h3>
                    <p> A mission statement is defined as an action-based statement that declares the purpose of an organization and how they serve their customers. This sometimes includes a description of the company</p>
                </div>
            </div>
        </div>-->
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="Sourcing" style="border-radius:18px 18px 18px 18px  !important;">
                <div class="single-box">
                    <div class="icon1">
                        <img src="../../../assets/services.svg" style="width: 20rem;" />
                    </div>
                    <div class="v_m_heading">Services</div>
                    <p>
                        Custom synthesis of NCE’s, Niche APIs and their advanced intermediates,  from a milligram to multi kilo &tonnage  scale.
                        ,Contract Manufacturing in FFS & FTE Mode,Process Development for API Intermediates
                        ,Custom  Synthesis of building blocks & Catalogue products
                        ,Custom synthesis of scaffolds and new molecules
                        ,Supply of Commercial Raw materials on commercial scale
                        ,Large scale contract Manufacturing under GMP / Non GMP

                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Medicinal or Synthetic Chemistry Services<br />
Process R&D<br />
API Intermediates<br />
Pharmaceutical Impurities<br />
Custom synthesis of Speciality Chemicals<br />
Sourcing and Procurement Services-->
