import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'

import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

import { Directive, HostListener } from '@angular/core'
import { CartService } from '../../Services/cart.service';

import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
    cartData = [];
    ProductData = [];
    CatlogID: any;
    Specdata = []
    i: any;
    CatID: any;
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';

    HomeUrl: any;
    Catalog_No: any;
    Cas: any;
    ChemicalName: any;
    EnquiryAry1: any;
    Formula: any;
    BoilingPoint: any;
    MolecularWeight: any;
    Moisture: any;
    MeltingPoint: any;
    cartlist: any;
    cartItems: any;
    items: any;
    constructor(public generalService: GeneralService, public http: HttpClient, public router: Router, public CartService: CartService ) {
        //this.http.get('../assets/WebService.json').subscribe((data: any) => {
        //    this.HomeUrl = data.Webservice;
        //});

        debugger
        this.CatID = localStorage.getItem('Cattegoryid')
        this.CatlogID = localStorage.getItem('CatlogID')
        this.cartlist = localStorage.getItem('cartlist');
        this.cartData = JSON.parse(localStorage.getItem('cartData'))
        this.CartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
        
            this.items = this.cartItems
        })
        console.log(this.cartData)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeUrl = data.Webservice;
        });
    
    }

    ngOnInit(): void {
      //  this.GetSpecificationdata(this.CatlogID)
        //this.ProductData = [];
        debugger
        this.CartService.getProducts().subscribe(res => {
            this.cartlist = res[0];
            localStorage.setItem("cartlist", this.cartlist);
            return this.cartlist
            
        })
    }
    //GetSpecificationdata(Catalog_No) {
    //    var url = "api/Product/GetProductSpecsUsing_Catlog";
    //    debugger
    //    // this.spinner.show();
    //    var UploadFile = new FormData();
    //    UploadFile.append("Catlog_No", this.CatlogID);
    //    this.generalService.PostData(url, UploadFile).then((data: any) => {
    //        debugger
    //        if (data != null) {
    //            this.Specdata = data.Specification;

    //            this.CatalogNo = (this.Specdata[0].CatalogNo);
    //            this.ChemicalName = (this.Specdata[0].ChemicalName);
    //            this.Cas = (this.Specdata[0].Cas);
    //            this.Formula = (this.Specdata[0].Formula);
    //            this.BoilingPoint = (this.Specdata[0].BoilingPoint);
    //            this.MolecularWeight = (this.Specdata[0].MolecularWeight);
    //            this.Moisture = (this.Specdata[0].Moisture);
    //            this.MeltingPoint = (this.Specdata[0].MeltingPoint);
    //        }

    //    }, err => {
    //    });
    //}
    back() {
        this.router.navigateByUrl('/Products');
    }
    deleteFromCart(index, P_ID) {
        debugger
      //  this.CartService.removeCartItems(index)
       
        this.cartItems.splice(index, 1); // Removes the item from the cartItems array based on index

        // Loop through cartItems to find and remove the item with matching productId from local storage
        for (let i = 0; i < this.cartItems.length; i++) {
            if (this.cartItems[i].P_ID === P_ID) {
                this.cartItems.splice(i, 1); // Remove the item from cartItems array
                break; // Exit the loop once the item is found and removed
            }
        }

        // Save the updated cartItems array to local storage
        localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
        this.CartService.updateLocalStorage()
        if (this.cartItems.length === 0) {
            // If cartItems is empty after deletion, clear both "Packagesarr" and "cartItems" from local storage
          //  localStorage.removeItem("Packagesarr");
            localStorage.removeItem("cartItems");

            // Show a warning alert
            this.generalService.ShowAlert('Warning', 'No items in the cart.', 'warning');

            // Redirect to Products page with categoryID 1
            this.router.navigate(['/Products'], { queryParams: { categoryID: 1 } });

            // Clear the cart service
            this.CartService.getProducts();
        }

        // Reload the current page
       // location.reload();
    }

    

        
    
    addtoEnquiry(cName, cas) {
        debugger
        var j = 1
        var k = 0
        if (this.cartData.length == 0) {
            this.cartData.push({ P_ID: this.CatlogID, ChemicalName: cName, Cas: cas, Qty: j })
        }
        else {
            for (var i = 0; i < this.cartData.length; i++) {
                if (this.cartData[i].P_ID == this.CatlogID) {
                    this.cartData[i].Qty = this.cartData[i].Qty + 1;
                    k++;
                }
            }
            if (k == 0) {
                this.cartData.push({ P_ID: this.CatlogID, ChemicalName: cName, Cas: cas, Qty: j })
            }
        }
        localStorage.setItem("cartData", JSON.stringify(this.cartData))
        this.router.navigateByUrl('/Products');
    }

    FilterList(id) {
        debugger
        localStorage.removeItem('catid');
        localStorage.setItem("catid", this.CatID);
        this.router.navigateByUrl('/Products');
    }
    onSubmit1(form: any) {
        debugger;

        this.EnquiryAry = []; this.EnquiryAry.push({ Name: form.name, number: form.number, Email: form.email, Message: form.message })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        var url = "api/Product/SendContatEnquirydetailstoadmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;

            //this.contactForm.reset();
            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'

            )
            // this.sendsms();
        });

    }
    onSubmit(form: any) {
        debugger;
        this.EnquiryAry1 = [];
        this.CartService.getProducts().subscribe(res => {
            this.cartlist = res[0];
        })
        for (var i = 0; i < this.cartlist.length; i++) { 
            debugger
        this.EnquiryAry1.push({
            Products: this.cartlist[i].ChemicalName,
            CatalogNo: this.cartlist[i].Catalog_No,
            CasNumber: this.cartlist[i].Cas,

            });
        }


        this.EnquiryAry = []; this.EnquiryAry.push({ name: form.name, number: form.number, email: form.email, message: form.message })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        UploadFile.append("Ticket2", JSON.stringify(this.EnquiryAry1));
        var url = "api/Product/EnquiryMailToAdmin1"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;


            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'
                
            ).then(() => {
                // Navigate to the products page after the alert is closed
                this.router.navigateByUrl('/Products');
            });
        });
    }

}
