
<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">Core Services</div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>Core Services</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!--<nav aria-label="breadcrumb"class="crumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item Home"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a style="color:white;">Core Services</a></li>
    </ol>
</nav>-->
<div class="container-fluid content_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-5 col-sm-12 col-xs-12" style="margin-top:2rem;">
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        
                        <p>Feasibility study of Chemical route</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon ">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        
                        <p>Cost Effective Route with Intellectual Property</p>
                        
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png"/>
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>Establishment of Robust, Scalable, Safe & Environment friendly processes</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png"/>
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>Technology Transfer, Process Development Report & Documentation</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png"/>
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>
                            We satisfy the client expectations by<b> supplying the high purity & quality products.</b>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                        <img src="https://img.icons8.com/dotty/100/000000/swipe-right.png" />
                    </div>
                    <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                        <p>Impurity profiling which includes Identification, Characterisation & Synthesis</p>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-7 col-sm-4 col4" style="text-align:right;">
                    <img src="../../../assets/img/images_21.jpg" style="width:80%;margin-top:2rem;" class="side_image" />
                </div>
                </div>
            </div>
        </div>
