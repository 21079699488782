import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../Services/generalservice.service';
import { CartService } from '../../../Services/cart.service';

//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    selectedItem: any = 'Home';
    ProductsSpecifications: any[];
    items: any[];
    FilterSpecData: any;
    filteredSpecifications: any[];
    SpecID: any;
    SpecName: any;
    Productsdata: any;
    ChemicalName: any;
    cartItemsCount: any;
    constructor(public generalService: GeneralService, public router: Router, public CartService: CartService) {

    }

    ngOnInit() {
        this.CartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
            this.cartItemsCount = res.length
        })
    }
    countReturn() {
        this.CartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemsCount = res.length
            return this.cartItemsCount
        })
        return this.cartItemsCount
    }
    clearValue() {
        this.Productsdata = "";
    }
    GetSpecdata(filteredSpecifications) {
        debugger
        this.Productsdata;
        localStorage.removeItem('Cattegoryid');
        localStorage.removeItem('CatlogID');
        localStorage.setItem("CatlogID", filteredSpecifications.Catalog_No)
        var route = this.router.url
        this.Productsdata = '';
        this.ChemicalName = '';
        if (route == '/products-info') {
            localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
            window.location.reload();
        }
        else {
            localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
            this.router.navigateByUrl('/products-info');


        }

    }
    filterSpecifications(event) {
        debugger
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        let query = event.query;

        var UploadFile = new FormData();
        UploadFile.append("name", query);
        var url = "/api/Product/GettingsearchProductCatlogandcasandchemicalname";
        debugger
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.filteredSpecifications = [];
            this.filteredSpecifications = data

        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });


    }


    FilterList(id) {
        debugger

        localStorage.removeItem('catid');
        localStorage.setItem("catid", id);
        var route = this.router.url
        if (route == '/Products') {
            window.location.reload();
        }
        else {
            this.router.navigateByUrl('/Products');

        }

    }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    listClick(val) {
        this.selectedItem = val;
    }
}
